
<template>
    <el-menu :default-active="activeMenu()" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse" background-color="#333"
      text-color="#fff"
      active-text-color="#409EFF">
        <h3 style="font-size:1.4rem">{{isCollapse? "W":"WMS"}}</h3>
        <!-- <el-menu-item index="/home">
            <i class="el-icon-menu"></i>
            <span slot="title">Home</span>
        </el-menu-item>
        <el-submenu index="/items">
            <template slot="title">
                <i class="el-icon-location"></i>
                <span slot="title">Items</span>
            </template>
            <el-menu-item-group>
                <el-menu-item index="/items/list">Items List</el-menu-item>
            </el-menu-item-group>
        </el-submenu>
        <el-submenu index="/users">
            <template slot="title">
                <i class="el-icon-location"></i>
                <span slot="title">Users</span>
            </template>
            <el-menu-item-group>
                <el-menu-item index="/users/list">Users List</el-menu-item>
            </el-menu-item-group>
        </el-submenu>
        <el-submenu index="/companies">
            <template slot="title">
                <i class="el-icon-location"></i>
                <span slot="title">Companies</span>
            </template>
            <el-menu-item-group>
                <el-menu-item index="/companies/list">Companies List</el-menu-item>
            </el-menu-item-group>
        </el-submenu> -->

        <MenuItem :menulist="dyMenuList"></MenuItem>
        <!-- <el-menu-item @click="clickMenu(item)" v-for="item in noChildren" :key="item.name" :index="item.path">
            <i class="el-icon-menu"></i>
            <span slot="title">{{item.label}}</span>
        </el-menu-item>
        <el-menu-item @click="clickMenu(item)" v-for="item in noChildren" :key="item.name" :index="item.path">
            <i class="el-icon-menu"></i>
            <span slot="title">{{item.label}}</span>
        </el-menu-item>
        <el-submenu v-for="item in haveChildren" :key="item.label" :index="item.label">
            <template slot="title">
                <i class="el-icon-location"></i>
                <span slot="title">{{item.label}}</span>
            </template>
            <el-menu-item-group v-for="subItem in item.children" :key="subItem.path">
                <el-menu-item @click="clickMenu(subItem)" :index="subItem.path">{{subItem.label}}</el-menu-item>
            </el-menu-item-group>
        </el-submenu> -->
    </el-menu>
</template>
<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}
.el-menu{
    height: 100vh;
    h3{
        color: #fff;
        text-align: center;
        line-height: 48px;
    }
}
</style>
  
<script>
import Cookie from "js-cookie"
import { mapState } from "vuex";
import MenuItem from "./MenuItem.vue";
export default {
    components:{
        MenuItem 
    },
    data() {
        return {
            // isCollapse: false,
            // menuData:[
            //     {path: "/home", name:"home", component: Home},
            //     {
            //         path: "/items", 
            //         name:"items", 
            //         component: Item,
            //         children:[
            //             {
            //                 path: "list", 
            //                 name:"ItemsList", 
            //                 component: Item, 
            //                 meta:{
            //                     activeMenu:"/items"
            //                 }
            //             },
            //             {
            //                 path: "add", 
            //                 name:"addItems", 
            //                 component: AddItem, 
            //                 meta:{
            //                     activeMenu:"/items"
            //                 }
            //             },
            //             {
            //                 path: ":id", 
            //                 name:"viewItems", 
            //                 component: ViewItem, 
            //                 meta:{
            //                     activeMenu:"/items"
            //                 }
            //             },
            //         ]
            //     },
            //     {path: "/users", name:"users", component: Users}
            // ]
        };
    },
    mounted(){
        // console.log("dyMenuList",this.dyMenuList)
    },
    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        clickMenu(item){
            // console.log(item,"click")
            // console.log(this.$route.name,item.name, "??Dadsadsdasd")

            //当页面的路由与跳转的路由不一致才允许跳转
            // if(this.$route.name !== item.name && !(this.$route.path === "/home" && (item.path === "/"))){
            //     this.$router.push(item.path)
            // }

            // this.$store.commit("selectMenu",item)
            
        },
        activeMenu(){
            let route = this.$route
            // console.log(route,"activeMenu()")

            let {name, meta} = route
            if(meta.activeMenu){
                return meta.activeMenu
            }
            return name
        }
    },
    computed:{
        // noChildren(){
        //     return this.menuData.filter(res => !res.children)
        // },
        // haveChildren(){
        //     return this.menuData.filter(res => res.children)
        // },
        isCollapse(){
            return this.$store.state.tab.isCollapse
        },
        ...mapState("menu", ["dyMenuList"])
        // menuData(){
        //     //判断当前数据，如果缓存中没有，当前store中去取
        //     return JSON.parse(Cookie.get("menu")) || this.$store.state.tab.menu
        // }
    }
}
</script>

<style lang="less" scoped>
.el-menu{
    border-right: 0;
}
</style>