<template>
    <el-row>
        <el-col :span="8" style="padding-right:10px">
            <div class="grid-content bg-purple">
                <el-card class="box-card">
                    <div class="user">
                        <div class="userinfo">
                            <p>User: <span class="name">{{userinfo.user}}</span></p>
                            <p class="access">Role: <span >{{ userinfo.role }}</span></p>
                        </div>
                    </div>
                    <!-- <div class="login-info">
                        <p>上次登录时间</p>
                        <p>上次登录地点</p>
                    </div> -->
                </el-card>
                <!-- <el-card style="margin-top: 20px;  height:460px">
                    <el-table :data="tableData" style="width: 100%">
                        <el-table-column v-for="(val, key) in tableLabel" :prop="key" :label="val" :key="val"></el-table-column>
                        
                    </el-table>
                </el-card> -->
            </div>
        </el-col>
        <el-col :span="16" style="padding-left:10px">
            <div class="num" v-if="!isCustomer">
                <el-card v-for=" (item, index) in countData" :key="index" :body-style="{display: 'flex', padding:0}">
                    <i class="icon" :class="`el-icon-${item.icon}`" :style="{background: item.color}"></i>
                    <div class="detail">
                        <p class="price">{{item.value}}</p>
                        <p class="desc">{{item.name}}</p>
                    </div>
                </el-card>
            </div>
            <el-card style="height: 280px" v-if="!isCustomer" >
                <div ref="echarts1" style="height: 280px"></div>
            </el-card>
            <div class="graph" v-if="!isCustomer">
                <el-card  style="height: 260px">
                    <div ref="echarts2" style="height: 260px"></div>
                </el-card>
                <el-card style="height: 260px">
                    <div ref="echarts3" style="height: 240px"></div>
                </el-card>
            </div>
        </el-col>
    </el-row>   
</template>

<script>
import {getData, getUserById} from "../api"
import * as echarts from "echarts"
import {mapState} from "vuex"
export default{
    data(){
        return{
            tableData: [{
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-04',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1517 弄'
            }, {
                date: '2016-05-01',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1519 弄'
            }, {
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1516 弄'
            }],
            tableLabel:{
                name:"姓名",
                todayBuy:"日购量",
                mounthBuy:"月购量",
                totalBuy:"总购量"
            },
            countData:[
                {
                    name:"Today's Orders",
                    value:23,
                    icon:"success",
                    color:"#999999"
                },
                {
                    name:"Today's Customers",
                    value:123,
                    icon:"success",
                    color:"#999999"
                },
                {
                    name:"Today's Invoices",
                    value:23,
                    icon:"success",
                    color:"#999999"
                },
                // {
                //     name:"今日支付订单",
                //     value:1234,
                //     icon:"success",
                //     color:"#999999"
                // },{
                //     name:"今日支付订单",
                //     value:1234,
                //     icon:"success",
                //     color:"#999999"
                // }
            ]
        }
    },
    mounted(){
        console.log(this)
        // getData().then(({data})=>{
        //     const {tableData} = data.data
        //     console.log(data.data)
        //     this.tableData = tableData

        //     const echarts1 = echarts.init(this.$refs.echarts1)

        //     var echarts1Option = {}
        //     //处理数据xAxis
        //     const {orderData, userData, videoData} = data.data

        //     const xAxis = Object.keys(orderData.data[0])
            
        //     const xAxisData= {  
        //         data: xAxis
        //     }
        //     echarts1Option.xAxis = {  
        //         data: orderData.date
        //     }
        //     echarts1Option.yAxis = {}

        //     echarts1Option.legend = xAxisData
        //     echarts1Option.series = []

        //     xAxis.forEach(key =>{
        //         echarts1Option.series.push({
        //             name: key,
        //             data: orderData.data.map(res => res[key]),
        //             type: "line"
        //         })
        //     })

        //     console.log(echarts1Option)

        //     //使用刚指定的配置项和数据显示图表

        //     echarts1.setOption(echarts1Option)

        //     //柱状图

        //     const echarts2 = echarts.init(this.$refs.echarts2)
        //     const echarts2Option = {
        //         legend:{
        //             //图例文字颜色
        //             textStyle:{
        //                 color: "#333"
        //             }
        //         },
        //         grid:{
        //                 left:"20%"
        //             },
        //             tooltip:{
        //                 trigger: "axis"
        //             },
        //             xAxis:{
        //                 type: "category",
        //                 data: userData.map(res => res.date),
        //                 axisLine: {
        //                     lineStyle: {
        //                         color: "#17b3a3"
        //                     }
        //                 },
        //                 axisLabel:{
        //                     interval:0,
        //                     color: "#333"
        //                 }
        //             },
        //             yAxis:[
        //                 {
        //                     type: "value",
        //                     axisLine: {
        //                         lineStyle: {
        //                             color:"#17b3a3"
        //                         }
        //                     }
        //                 }
        //             ],
        //             color: ["#2ec7c9", "#b6a2de"],
        //             series:[
        //                 {
        //                     name:"新增用户",  
        //                     data: userData.map(res => res.new),
        //                     type:"bar"
        //                 },
        //                 {
        //                     name:"活跃用户",
        //                     data: userData.map(res => res.active),
        //                     type:"bar"
        //                 }
        //             ]
        //     }
        //     echarts2.setOption(echarts2Option)

        //     //饼状图
        //     const echarts3 = echarts.init(this.$refs.echarts3)
        //     const echarts3Option = {
        //         tooltip:{
        //             trigger: "item"
        //         },
        //         color:[
        //             "#0f78f4",
        //             "#dd536b",
        //             "#9462e5",
        //             "#a6a6a6",
        //             "#e1bb22",
        //             "#39c362",
        //             "#3ed1cf"
        //         ],
        //         series:[
        //             {
        //                 data: videoData,
        //                 type: "pie"
        //             }
        //         ]
        //     }
        //     echarts3.setOption(echarts3Option)
        // })
        // getUserById().then(res => console.log(res))
    },
    computed:{
        ...mapState("login", ["userinfo"]),
        isCustomer(){
            return this.userinfo.role === "customer" 
        }
    }
}
</script>

<style lang="less" scoped>
.user{
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
    img{
        width: 150px;
        height: 150px;
        border-radius: 50%;
        margin-right: 40px;
    };
    .userinfo{
        .name{
            font-size: 20px;
            margin-bottom: 10px;
        }
        .access{
            color: #999999
        }
    }
}
.login-info{
    p{
        line-height: 28px;
        font-size: 14px;
        color: #999999;
        span{
            color: #666666;
            margin-left:60px
        }
    }
}
.num{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .icon{
        width:80px;
        height: 80px;
        font-size: 30px;
        text-align: center;
        line-height: 80px;
        color:#fff;
    }
    .detail{
        margin-left: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .price{
            font-size: 30px;
            margin-bottom: 10px;
            line-height: 30px;
            height: 30px;
        }
        .desc{
            font-size: 14px;
            color: #999999;
            text-align: center;
        }
    }
    .el-card{
        width: 32%;
        margin-bottom:20px
    }
}
.graph{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .el-card{
        width: 48%;
    }
}
</style>