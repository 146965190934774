<template>
    <div class="container">
        <div class="title">New Item</div>
        <div class="content">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top" label-width="100px" class="demo-ruleForm">
                <el-tabs v-model="activeTabName" @tab-click="handleClick">
                    <el-tab-pane label="General Details" name="first">
                        <el-row :gutter="20">
                            <el-col :span="24">
                                <el-form-item label="Serial Number" prop="serial">
                                    <el-input v-model="ruleForm.serial"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="Order Number" prop="order_number">
                                    <el-input v-model="ruleForm.order_number"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Ref Number" prop="ref_number">
                                    <el-input v-model="ruleForm.ref_number"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="Test Date" prop="test_date">
                                    <el-date-picker type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"  v-model="ruleForm.test_date" style="width: 100%;"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Test Schedule" prop="test_schedule">
                                    <el-select v-model="ruleForm.test_schedule" filterable style="width: 100%">
                                        <el-option v-for="schedule in testScheduleOptions" :key="schedule.id" :label="schedule.name" :value="schedule.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item label="Comments" prop="description">
                            <el-input type="textarea" max="3" min="2" v-model="ruleForm.description" resize="none"></el-input>
                        </el-form-item>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="Authorized By" prop="authorized_by">
                                    <el-select v-model="ruleForm.authorized_by" filterable style="width: 100%">
                                        <el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Customer" prop="user_id">
                                    <el-select v-model="ruleForm.user_id" filterable style="width: 100%">
                                        <el-option v-for="customer in customers" :key="customer.id" :label="customer.name" :value="customer.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="24">
                                <el-form-item label="Status"  prop="status">
                                    <el-radio-group v-model="ruleForm.status" size="small">
                                        <el-radio v-for="item in statusOptions" :label="item.value" :key="item.name" border :disabled="item.disabled">{{item.name}}</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="Assembly Details" name="second">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="Hose Code" prop="code">
                                    <!-- <el-select v-model="ruleForm.code" filterable :filter-method="filterUser" style="width: 100%">
                                        <el-option v-for="item in itemCodeOptionsFilter" :key="item.id" :label="item.code" :value="item.code"></el-option>
                                    </el-select> -->
                                    <el-select v-model="ruleForm.code" filterable style="width: 100%">
                                        <el-option v-for="item in itemCodeOptions" :key="item.id" :label="item.code" :value="item.code"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Hose Type" prop="hosetype">
                                    <el-input v-model="ruleForm.hosetype" :disabled="isHosetypeDisabled"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="Static Length (mm)" prop="static_length">
                                    <el-input v-model.number="ruleForm.static_length">
                                        <el-select v-model="ruleForm.static_length_unit" slot="append" placeholder="Select" class="input-with-select">
                                            <el-option v-for="item in staticLengthUnitOptions" :key="item.name" :label="item.name" :value="item.value"></el-option>
                                        </el-select>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Nominal Bore (mm)" prop="nominal_bore">
                                    <el-input v-model.number="ruleForm.nominal_bore"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="4">
                                <el-form-item label="End A" prop="end_a_code">
                                    <el-select v-model="ruleForm.end_a_code" filterable style="width: 100%">
                                        <el-option v-for="item in endabOptions" :key="item.id" :label="item.code" :value="item.code"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="" prop="end_a">
                                    <br />
                                    <el-input v-model="ruleForm.end_a" :disabled="isEndADisabled"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="End A Retention" prop="end_a_retention">
                                    <el-select v-model="ruleForm.end_a_retention" filterable style="width: 100%">
                                        <el-option v-for="item in retentionOptions" :key="item.name" :label="item.name" :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="4">
                                <el-form-item label="End B" prop="end_b_code">
                                    <el-select v-model="ruleForm.end_b_code" filterable style="width: 100%">
                                        <el-option v-for="item in endabOptions" :key="item.id" :label="item.code" :value="item.code"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="" prop="end_b">
                                    <br />
                                    <el-input v-model="ruleForm.end_b" :disabled="isEndBDisabled"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="End B Retention" prop="end_b_retention">
                                    <el-select v-model="ruleForm.end_b_retention" filterable style="width: 100%">
                                        <el-option v-for="item in retentionOptions" :key="item.name" :label="item.name" :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="Assembly Location" prop="location">
                                    <el-select v-model="ruleForm.location" filterable style="width: 100%">
                                        <el-option v-for="item in locationOptions" :key="item.name" :label="item.name" :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Manufacture Date" prop="time_manufactured">
                                    <el-date-picker type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" v-model="ruleForm.time_manufactured" :readonly="is_time_manufactured_readonly" style="width: 70%; margin-right: 2%;"></el-date-picker>
                                    <el-checkbox v-model="ruleForm.is_time_manufactured_na" :true-label="1" :false-label="0">N/A</el-checkbox>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="Test Details" name="third">
                        <el-divider content-position="left"><h5>Pressure Test</h5></el-divider>
                        <el-row :gutter="20">
                            <el-col :span="24" style="text-align: right;">
                                <el-switch v-model.number="ruleForm.test_pressure_display" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0" active-text="Show on Certificate" inactive-text="Hide on Certificate"></el-switch>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Test Pressure" prop="test_pressure">
                                    <el-input v-model="ruleForm.test_pressure">
                                        <el-select v-model="ruleForm.test_pressure_unit" slot="append" placeholder="Select" class="input-with-select">
                                            <el-option v-for="item in testPressureUnitOptions" :key="item.name" :label="item.name" :value="item.value"></el-option>
                                        </el-select>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="" prop="test_pressure_result">
                                    <br />
                                    <el-switch v-model.number="ruleForm.test_pressure_result" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0" active-text="Pass" inactive-text="Fail"></el-switch>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <br />
                        <el-divider content-position="left"><h5>Elongation Test</h5></el-divider>
                        <el-row :gutter="20">
                            <el-col :span="24" style="text-align: right;">
                                <el-switch v-model.number="ruleForm.test_elongation_display" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0" active-text="Show on Certificate" inactive-text="Hide on Certificate" @change="elongationDisplayChangeHandler"></el-switch>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Test Length (mm)" prop="test_length">
                                    <el-input v-model="ruleForm.test_length"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="" prop="test_length_result">
                                    <br />
                                    <el-switch v-model.number="ruleForm.test_length_result" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0" active-text="Pass" inactive-text="Fail"></el-switch>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="Final Length (mm)" prop="final_length">
                                    <el-input v-model="ruleForm.final_length"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="" prop="final_length_result">
                                    <br />
                                    <el-switch v-model.number="ruleForm.final_length_result" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0" active-text="Pass" inactive-text="Fail"></el-switch>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <br />
                        <el-divider content-position="left"><h5>Continuity & Electrical Resistant Test</h5></el-divider>
                        <el-row :gutter="20">
                            <el-col :span="24" style="text-align: right;">
                                <el-switch v-model.number="ruleForm.test_continuity_display" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0" active-text="Show on Certificate" inactive-text="Hide on Certificate" @change="continuityDisplayChangeHandler"></el-switch>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Continuity" prop="continuity">
                                    <!-- <el-input v-model.number="ruleForm.continuity"></el-input> -->
                                    <el-select v-model="ruleForm.continuity" filterable style="width: 100%">
                                        <el-option v-for="item in continuityOptions" :key="item.name" :label="item.name" :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="" prop="continuity_result">
                                    <br />
                                    <el-switch v-model.number="ruleForm.continuity_result" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0" active-text="Pass" inactive-text="Fail"></el-switch>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="Resistance (ohms)" prop="resistance">
                                    <el-input v-model="ruleForm.resistance">
                                        <template slot="append">ohms</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="" prop="resistance_result">
                                    <br />
                                    <el-switch v-model.number="ruleForm.resistance_result" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0" active-text="Pass" inactive-text="Fail"></el-switch>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                </el-tabs>
                <el-form-item class="btn-fields">
                    <el-button type="primary" @click="submitForm('ruleForm')" :disabled="isSubmiting">{{isSubmiting? "Loading...":"Submit"}}</el-button>
                    <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
                </el-form-item>
            </el-form>
        </div>

    </div>

</template>

<script>
import { mapState } from "vuex";
import { addNewItem } from "../../api/items";
import { getEmployees, getAllCustomers } from "../../api/user";
import Item from "../../mixins/item"

export default {
    mixins: [Item],
    data() {
        return {
            ruleForm: {
                serial: "",
                test_schedule: "",
                test_date: "",
                // time_retest: "",
                order_number: "",
                ref_number: "",
                authorized_by: "",
                description: "",
                hosetype:"",
                code:"",
                static_length: "",
                static_length_unit: 2,
                nominal_bore: "",
                end_a: "",
                end_a_code: "",
                end_a_retention:"",
                end_b:"",
                end_b_code: "",
                end_b_retention:"",
                location:"",
                time_manufactured:"",
                is_time_manufactured_na: 0,
                test_pressure:"",
                test_pressure_unit: 1,
                test_pressure_result: 1,
                test_pressure_display: 1,
                test_length:"",
                test_length_result: 1,
                final_length:"",
                final_length_result: 1,
                test_elongation_display: 1,
                continuity:"End to End",
                continuity_result: 1,
                resistance:"",
                resistance_result: 1,
                test_continuity_display: 1,
                authorized_by: "",
                user_id: "",
                status:"",
                created_by: ""
            },
            authors: [
                {
                    id: 0,
                    name: "Admin (Admin)"
                },
                {
                    id: 1,
                    name: "Avondale"
                },
                {
                    id: 2,
                    name: "Pipeline Ashburton (Pipeline Ashburton)"
                },
                {
                    id: 3,
                    name: "Pipeline New PlyMouth(Pipeline New PlyMouth)"
                },
                {
                    id: 4,
                    name: "Pipeline Taurange (Pipeline Taurange)"
                },
                {
                    id: 5,
                    name: "Pipeline Timaru (Pipeline Timaru)"
                },
                {
                    id: 6,
                    name: "Ross Burns (Pipeline Hamilton)"
                },
                {
                    id: 7,
                    name: "wam (wam)"
                },
            ],
            customers:[],
            employees:[],
            isSubmiting: false,
            isHosetypeDisabled: true,
            isEndADisabled: true,
            isEndBDisabled: true,
            is_time_manufactured_readonly: false
            // itemCodeOptionsFilter: []
        };
    },
    mounted(){

        this.fetchDatails()
    },
    methods: {
        /**
         * 用于在item过多导致select组件产生卡顿的时候，前端将后端返回来的数据进行过滤在传到select组件，暂时用不到，暂时注释
         */
        // filterUser(query=""){
        //     console.log(query)
        //     // itemCodeOptions
        //     let arr = this.itemCodeOptions.filter((item) => {
        //         console.log("item.code", item.code, query, item.code.includes(query))
        //         return item.code.includes(query.toUpperCase())
        //     })
        //     console.log("arr",arr)
        //     if (arr.length > 70) {
        //         this.itemCodeOptionsFilter = arr.slice(0, 70)
        //     } else {
        //         this.itemCodeOptionsFilter = arr
        //     }
        // },
        fetchDatails(){
            
            this.getItemOptions()
            this.getEmployeeList()
            this.getCustomerList()
        },
        submitForm(formName) {
            this.isSubmiting = true
            this.ruleForm.created_by = this.userinfo.uid
            this.$refs[formName].validate((valid) => {
                console.log(valid,"valid")
                if (valid) {
                    // console.log(this.ruleForm)
                    addNewItem(this.ruleForm).then(res=> {
                        console.log(res)
                        const {code, message, data} = res.data
                        if(code === 200){
                            this.$message({message: message, type: 'success'});
                            console.log(res.data)
                            this.$router.replace({name:"EditItem", params:{ id: data.id}})
                        }else{
                            this.$message.error({message: message});
                        }

                    }).catch(err=>{
                        // console.log(err)
                        this.$message.error({message: err.message});
                    })
                    setTimeout(() => {
                       this.isSubmiting = false 
                    }, 2000);
                } else {
                    this.$message.error({message: "Some errors on the form, please check"})
                    // console.log('error submit!!');
                    this.isSubmiting = false
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        handleClick(tab, event) {
            // console.log(tab, event);
        },
        getEmployeeList(){
            getEmployees().then(res => {
                const {code, data} = res.data
            if(code === 200){
                this.employees = data
            }
            }).catch(err=>err)
        },
        getCustomerList(){
            getAllCustomers().then(res => {
                const {code, data} = res.data
            if(code === 200){
                this.customers = data
            }
            }).catch(err=>err)
        }
    },
    computed:{
        ...mapState("login",["userinfo"]),
    },
    watch:{
        "ruleForm.code":{
            handler(newValue, oldValue) {
                if(newValue === "MH"){
                    this.ruleForm.hosetype = ""
                    this.isHosetypeDisabled = false
                }else{
                    this.ruleForm.hosetype = this.itemCodeOptions.find(res => res.code === newValue)?.description

                    // this.ruleForm.hosetype = this.itemCodeOptionsFilter.find(res => res.code === newValue)?.description
                    this.isHosetypeDisabled = true
                }

                const isComposiste = this.itemCodeOptions.find(res => res.code === newValue)?.is_composiste

                // const isComposiste = this.itemCodeOptionsFilter.find(res => res.code === newValue)?.is_composiste

                if(isComposiste === 0){
                    this.removeElongationTestValidate()
                    this.removeContinuityTestValidate()
                }else{
                    this.addElongationTestValidate()
                    this.addContinuityTestValidate()
                }

                this.clearValidate()

                this.ruleForm.test_elongation_display = isComposiste
                this.ruleForm.test_continuity_display = isComposiste
            },
            deep: true,           
        },
        "ruleForm.end_a_code":{
            handler(newValue, oldValue) {
                if(newValue === "MF"){
                    this.ruleForm.end_a = ""
                    this.isEndADisabled = false
                }else{
                    this.ruleForm.end_a = this.endabOptions.find(res => res.code === newValue)?.description
                    this.isEndADisabled = true
                }
            },
            deep: true,           
        },
        "ruleForm.end_b_code":{
            handler(newValue, oldValue) {
                if(newValue === "MF"){
                    this.ruleForm.end_b = ""
                    this.isEndBDisabled = false
                }else{
                    this.ruleForm.end_b = this.endabOptions.find(res => res.code === newValue)?.description
                    this.isEndBDisabled = true
                }
            },
            deep: true,           
        },
        "ruleForm.is_time_manufactured_na":{
            handler(newValue, oldValue) {
               if(newValue === 1){
                    this.ruleForm.time_manufactured = '1900-01-01'
                    this.is_time_manufactured_readonly = true
                    this.$refs.ruleForm.validateField('time_manufactured');
               }else{
                    this.ruleForm.time_manufactured = ''
                    this.is_time_manufactured_readonly = false
               }
            },
            deep: true,           
        },
    }
}
</script>
<style lang="less" scoped>
.container{
    .title {
        // background-color: #eee;
        font-size: 1.8rem;
        color: #333;
        padding: 10px;
        font-weight: bold;
    }
    .content{
        // padding: 0 3%;

        :deep(.el-row){
            margin-bottom: 10px;
        }

        :deep(.el-form-item){
            margin-bottom:0;
            
        }
        :deep(.el-form--label-top .el-form-item__label){
            padding: 0;
            font-weight: bold
        }
        .btn-fields{
            text-align: right;
            margin-top: 15px;
        }

        :deep(.el-tab-pane){
            padding-bottom: 25px;
        }

        :deep(.el-input-group__append){
            width: 15%;
        }
    }
}
</style>