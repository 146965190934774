<template>
    <div>
        <el-row :gutter="20">
            <el-col :span="12" v-if="!isCustomer">
                <el-button type="primary" @click="addItem">Add New Item</el-button>
            </el-col>
            <el-col :span="!isCustomer ? 12 : 24" style="text-align: right;">
                <el-select v-model="orderBy" placeholder="Order By" @change="orderChangeHandler" style="margin-right: 10px">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-input :placeholder="searchFieldPlaceholder" v-model="searchData" @input="seachHandler" clearable
                    autofocus style="width: 300px;">
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
            </el-col>
        </el-row>
        <el-table :data="tableData" stripe style="width: 100%" @row-dblclick="handleEdit" tooltip-effect="dark">
            <el-table-column prop="serial" label="Serial No." width="120"></el-table-column>
            <el-table-column label="Status" width="80">
                <template slot-scope="scope">
                    <template v-if="scope.row.status === 1">
                        <font-awesome-icon v-if="scope.row.remaining_days > 30"  icon="fa-solid fa-battery-full" style="color: #5cb85c; font-size: 1.5rem;"/>
                        <font-awesome-icon v-else-if="scope.row.remaining_days <= 30 && scope.row.remaining_days >= 8" icon="fa fa-battery-three-quarters" style="color: #f1c40f; font-size: 1.5rem;"/>
                        <font-awesome-icon v-else-if="scope.row.remaining_days <= 7 && scope.row.remaining_days >= 4" icon="fa-solid fa-battery-half" style="color: #f1c40f; font-size: 1.5rem;"/>
                        <font-awesome-icon v-else-if="scope.row.remaining_days <= 3" icon="fa-solid fa-battery-quarter" style="color: red; font-size: 1.5rem;"/>
                    </template>
                    <template v-else-if="scope.row.status === 2">
                        <font-awesome-icon icon="fa-solid fa-battery-empty" style="color: purple; font-size: 1.5rem;"/>
                    </template>
                </template>
            </el-table-column>
            <el-table-column v-if="!isCustomer" prop="code" label="Hose Code" width="130"></el-table-column>
            <el-table-column prop="hosetype" label="Hose Type" show-overflow-tooltip></el-table-column>
            <el-table-column prop="nominal_bore" label="Nominal Bore (mm)" align="right"></el-table-column>
            <el-table-column prop="time_retest" label="Re-test Date" width="130"></el-table-column>
            <el-table-column prop="refNumber" label="Ref Number" width="130"></el-table-column>
            <el-table-column prop="length" label="Length (mm)" align="right"></el-table-column>
            <el-table-column v-if="!isCustomer" prop="customer" label="Customer" show-overflow-tooltip></el-table-column>
            <el-table-column prop="author" label="Author" show-overflow-tooltip></el-table-column>
            <el-table-column prop="createdDate" label="Created Date"></el-table-column>
            <el-table-column label="">
                <template slot-scope="scope">
                    <!-- <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">{{isCustomer? 'View': 'Edit'}}</el-button> -->
                    <el-button v-if="!isCustomer" size="mini" type="danger" plain
                        @click="handleDelete(scope.$index, scope.row)"><i class="el-icon-delete"></i></el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @current-change="getPagination" :current-page="currentPage" :page-sizes="[100, 200, 300, 400]"
            :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
import { getItemTableData, deleteItemById, getItemsByUserId, getItemBySearch } from "../../../api/items";
import { mapState } from "vuex"
import { debounce } from "lodash"
export default {
    data() {
        return {
            tableData: [],
            total: 0,
            pageSize: 50,
            searchData: "",
            currentPage: 1,
            options: [ 
                { value: "time_created", label: 'Created Date↑' }, 
                { value: 'time_created DESC', label: 'Created Date↓' }, 
                { value: 'time_retest', label: 'Re-test Date↑' }, 
                { value: 'time_retest DESC', label: 'Re-test Date↓' },
                { value: 'hosetype', label: 'Hose Type↑' },
                { value: 'hosetype DESC', label: 'Hose Type↓' },
            ],
            orderBy: 'time_created DESC'
        }
    },
    created() {
        this.getpageList()
    },
    methods: {
        getpageList(page = 1) {
            this.currentPage = page

            const { currentPage, searchData, pageSize } = this

            getItemBySearch({ uid: this.userinfo.uid, page_num: currentPage, page_size: pageSize, searchText: searchData, orderBy: this.orderBy }).then(res => {
                // console.log(res, "sjjjsjsjs")
                const { code, data } = res.data
                if (code === 200) {
                    this.tableData = data.result
                    this.total = data.page.total
                }
            }).catch(err => {
                console.error(err.message)
            })
        },
        getPagination(page) {
            this.getpageList(page)
        },
        addItem() {
            // console.log(this.$router)
            this.$router.push({ name: "addItems" })
        },
        handleEdit(row, column, event) {
            // console.log(index, row, event);
            if (this.userinfo.role === "customer") {
                this.$router.push({ name: "viewItems", params: { id: row.id } })
            } else {
                this.$router.push({ name: "EditItem", params: { id: row.id } })
            }
        },
        handleDelete(index, row) {

            // console.log(index, row);
            this.$confirm('Are you sure to delete this item?', '', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'warning'
            }).then(() => {
                deleteItemById(row.id).then(res => {
                    console.log(res)
                    const { code, message, data } = res.data
                    if (code === 200) {
                        this.$message({ type: 'success', message: message });
                        this.tableData = this.tableData.filter(res => res.id !== data.id)
                    } else {
                        this.$message({ type: 'warning', message: message });
                    }
                }).catch(err => {
                    this.$message.error({ message: err.message });
                })

            }).catch(_ => _);
        },
        seachHandler: debounce(function (query) {
            this.searchData = query
            this.getpageList()
        }, 1000),
        orderChangeHandler(val){
            console.log(val,'orderChangeHandler')
            this.orderBy = val
            this.getpageList()
        }
    },
    computed: {
        ...mapState("login", ["userinfo"]),
        isCustomer() {
            return this.userinfo.role === "customer"
        },
        searchFieldPlaceholder(){
            return !this.isCustomer? 'Search(Serial, RefNumber, Customer)' : 'Search(Serial, RefNumber)'
        }
    }
}
</script>

<style lang="less" scoped>

:deep(.el-table tr:hover){
    cursor: pointer;
}
</style>