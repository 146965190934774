<template>
    <div>
        <template v-for="(item, index) in menulist">
            <el-submenu :index="item.name" :key="index" v-if="(item.children && item.children.length > 0)">
                <template slot="title">
                    <i :class="item.meta.icon"></i>
                    <span slot="title" :style="isCollapse? 'visibility: hidden': ''">{{item.name}}</span>
                </template>
                <el-menu-item-group>
                    <!-- 渲染二级导航 需要循环遍历 递归组件-->
                    <!-- <el-menu-item index="/items/list">Items List</el-menu-item>÷\ -->
                    <MenuItem :menulist="item.children"/>
                </el-menu-item-group> 
            </el-submenu>
            <el-menu-item v-else :index="item.name" :key="index" v-show="!item.meta.hide" @click="clickMenu(item)">
                <i :class="item.meta.icon? item.meta.icon : ''"></i>
                <span slot="title">{{item.name}}</span>
            </el-menu-item>
            
        </template>
        
    </div>
</template>
<script>
import menu from "@/store/menu";
import {mapState} from "vuex"
export default{
    name:"MenuItem",
    props:["menulist"],
    mounted(){
        
    },
    methods:{
        clickMenu(item){
            // this.$router.push({name: item.name})
             //当页面的路由与跳转的路由不一致才允许跳转
            if(this.$route.name !== item.name && !(this.$route.path === "/home" && (item.path === "/"))){
                this.$router.push({name: item.name})
            }
        }
    },
    computed:{
        ...mapState({
            isCollapse: state => state.tab.isCollapse
        })
    }
}
</script>
<style lang="less" scoped>
</style>