<template>
  <div>
    <el-row :gutter="20">
        <el-col :span="12">
            <el-button type="primary" @click="dialogFormVisible = true">Add New Employee</el-button>
        </el-col>
    </el-row>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="name" label="Name"></el-table-column>
      <el-table-column prop="file_url" label="Signature">
        <template slot-scope="scope">
          <el-image v-if="scope.row.file_url" style="width: 100px; height: 50px" :src="scope.row.file_url" fit="cover"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="" align="right">
        <template slot-scope="scope">
            <!-- <el-button size="mini" type="danger" plain @click="handleDelete(scope.$index, scope.row)"><i class="el-icon-delete"></i></el-button> -->
            <el-dropdown>
              <el-button size="mini" type="info" plain>
                Action<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-upload"  @click.native="signatureUploadHandler(scope.$index, scope.row)">Signature Upload</el-dropdown-item>
                <el-dropdown-item icon="el-icon-delete" @click.native="handleDelete(scope.$index, scope.row)">Delete</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :current-page="currentPage" :page-sizes="[100, 200, 300, 400]"
      :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="total">
    </el-pagination>

    <el-dialog title="Add New Employee" :visible.sync="dialogFormVisible" width="30%">
      <el-form :model="form">
        <el-form-item label="Employee Name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Cancel</el-button>
        <el-button type="primary" @click="submitHandler">Submit</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="'Signature Upload - ' + selectedPerson?.name" :visible.sync="isSignatureUploadDialogShowing" width="30%">
      <el-upload ref="upload" drag action :before-upload="onBeforeUploadImage" :http-request="uploadImageHandler"
        :on-remove="handleRemove" :on-success="fileUploadSuccessHandler" :limit="1" :on-exceed="fileUploadExceedHandler">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
        <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 5MB</div>
      </el-upload>
    </el-dialog>
  </div>
</template>

<script>
import { getAllEmployee, addNewEmployee, deleteEmployeeById, uploadEmployeeImage } from '@/api/employee';
export default{
  mounted(){
    this.fetchData()
  },
  data() {
    return {
      tableData: [],
      total:0,
      pageSize: 100,
      currentPage: 1,
      searchData: "",

      dialogFormVisible:false,
      form:{
        name: ''
      },

      isSignatureUploadDialogShowing: false,
      selectedPerson:null
    }
  },
  methods:{
    fetchData(){
      getAllEmployee().then(res => {
        if(res.data.code === 200){
          this.tableData = res.data.data.result
          this.total = res.data.data.page.total
          // this.pageSize = res.data.data.page.page_size
        }
      })
    },
    submitHandler(){

      if(this.form.name === ""){
        this.$message.error({message: "Failed to add empty name"});
        return
      }

      addNewEmployee(this.form).then(res => {
        console.log(res)
        const {code, data, message} = res.data
        if(code === 200){
          this.tableData.push({is_delete: 0, ...data})
          this.$message({message: message, type: 'success'});
        }
        this.dialogFormVisible = false
      }).catch(err => {
        this.$message.error({message: err.message});
      })
      // console.log(this.form)
      // this.dialogFormVisible = false
    },
    handleDelete(index, row) {
      this.$confirm('Are you sure to delete this employee?', '', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(() => {
        
        deleteEmployeeById(row.id)
          .then(res =>{
            console.log(res)
            const {code, message, data} = res.data
            if(code === 200){
              this.$message({message: message, type: 'success'});
              this.tableData = this.tableData.filter(res => res.id !== data.id)
            }else{
              this.$message({message: message, type: 'warning'});
            }
          }).catch(err =>{
            this.$message.error({message: err.message});
          })
      }).catch(() => {
        // this.$message({
        //     type: 'info',
        //     message: 'Action '
        // });          
      });
    },
    //+++++++++++++++++
    handleRemove(file, fileList) {
      // console.log("handleRemove: ",file, fileList);
    },
    handlePreview(file) {
      // console.log("handlePreview:", file);
    },
    onBeforeUploadImage(file){
      // console.log("onBeforeUploadImage:", file)
      const isImage = file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png"
      const isLimit = file.size / 1024 / 1024 < 5
      // console.log("isImage:", isImage, "isLimit:", isLimit)

      if (!isImage) {
        this.$message.error('Image must be JPG/PNG/JPEG format!!')
      }
      if (!isLimit) {
        this.$message.error('Image size can not exceed 5MB!')
      }

      return isImage && isLimit
    },
    uploadImageHandler(param){
      // console.log("uploadImageHandler:", param)
      let data = new FormData()
      data.append("image", param.file)
      // console.log("call api: ", data)
      // console.log("selectedPerson: ", this.selectedPerson)
      uploadEmployeeImage(this.selectedPerson.id, data).then(res => {
        //onSuccess 才会触发成功的icon
        param.onSuccess({seletedId: this.selectedPerson.id, response: res})
      }).catch(err => {
        console.log(err)
        param.onError()
      }).finally(() => {
        this.isSignatureUploadDialogShowing = false
        this.selectedPerson = null
        this.$nextTick(() => {
          this.$refs.upload.clearFiles()
        })
      })
    },
    fileUploadSuccessHandler({seletedId, response}, file, fileList){
      console.log({seletedId, response}, file, fileList)
      const index = this.tableData.findIndex(res => res.id === seletedId)
      this.tableData[index].file_url = response.data.data.imageUrl
      this.$message({message: response.data.message, type: 'success'});
    },
    signatureUploadHandler(index, row){
      // console.log(index, row)
      this.isSignatureUploadDialogShowing = true
      this.selectedPerson = row
    },
    fileUploadExceedHandler(files, fileList) {
      //用于防止一次性添加超过一张图片
      //没有用到，在成功的时候就把弹窗关掉了，这方法没用上
      this.$message.warning(`The limit is 1, you selected ${files.length} files this time, add up to ${files.length + fileList.length} totally`);
    },
  }
}
</script>