<template>
    <div class="container">
        <div class="title">
            Add New Company
        </div>
        <div class="content">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top" label-width="100px" class="demo-ruleForm">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="Company Name" prop="name">
                            <el-input v-model="ruleForm.name" id="name-input"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="Email" prop="email">
                            <el-input v-model="ruleForm.email" id="email-input"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Phone" prop="phone">
                            <el-input v-model="ruleForm.phone" id="phone-input"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="Address Line 1" prop="address_line1">
                            <el-input v-model="ruleForm.address_line1" id="address_line1-input"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Address Line 2" prop="address_line2">
                            <el-input v-model="ruleForm.address_line2" id="address_line2-input"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="Suburb" prop="address_suburb">
                            <el-input v-model="ruleForm.address_suburb" id="suburb-input"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="City" prop="address_city">
                            <el-input v-model="ruleForm.address_city" id="city-input"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="Country" prop="address_country">
                            <el-input v-model="ruleForm.address_country" id="country-input"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Postcode" prop="address_postcode">
                            <el-input v-model="ruleForm.address_postcode" id="postcode-input"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item class="btn-fields">
                    <el-button type="primary" :loading="isLoading" @click="submitForm('ruleForm')">Submit</el-button>
                    <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
                </el-form-item>
            </el-form>
        </div>

    </div>

</template>

<script>
import { addNewCompany } from "../../../api/companies";
// import { getRoles, addNewUser, getUserById } from "../../../api/user";
export default {
    data() {
        return {
            ruleForm: {
                name:"",
                email:"",
                phone:"",
                address_line1: "",
                address_line2: "",
                address_suburb:"",
                address_city:"",
                address_country:"",
                address_postcode:"",
                address_latitude:"",
                address_longitude:""
            },
            rules: {
                name: [
                    { required: true, message: 'This value is required.', trigger: 'change' }
                ],
            },
            isLoading:false
        };
    },
    mounted(){
        //set google address finder for address_line1
        const origin = new google.maps.places.Autocomplete(
            document.getElementById('address_line1-input'),
            {types: ['geocode'],componentRestrictions: {country: "nz"}}
        )
        origin.addListener("place_changed", () => {
            const place = origin.getPlace()
            // console.log(place)
            const streetIndex = place.address_components.findIndex(res => res.types.includes("street_number"))
            const routeIndex = place.address_components.findIndex(res => res.types.includes("route"))
            const suburbIndex = place.address_components.findIndex(res => res.types.includes("sublocality"))
            const cityIndex = place.address_components.findIndex(res => res.types.includes("locality"))
            const countryIndex = place.address_components.findIndex(res => res.types.includes("country"))
            const postcodeIndex = place.address_components.findIndex(res => res.types.includes("postal_code"))

            // console.log(streetIndex,"street")
            // console.log(routeIndex,"route")
            // console.log(suburbIndex,"suburb")
            // console.log(cityIndex,"city")
            // console.log(countryIndex,"country")
            // console.log(postcodeIndex,"postcode")
            // console.log(place.geometry.location.lat(), place.geometry.location.lng())

            const street = streetIndex >= 0? place.address_components[streetIndex].long_name : ""
            const route = routeIndex >= 0? place.address_components[routeIndex].long_name : ""
            this.ruleForm.address_line1 = `${street} ${route}`
            this.ruleForm.address_suburb = suburbIndex >= 0? place.address_components[suburbIndex].long_name : ""
            this.ruleForm.address_city = cityIndex >= 0? place.address_components[cityIndex].long_name : ""
            this.ruleForm.address_country = countryIndex >= 0? place.address_components[countryIndex].long_name : ""
            this.ruleForm.address_postcode = postcodeIndex >= 0? place.address_components[postcodeIndex].long_name : ""
            this.ruleForm.address_latitude = place.geometry.location.lat()
            this.ruleForm.address_longitude = place.geometry.location.lng()
        })
    },
    methods: {
        submitForm(formName) {
            this.isLoading = true
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    addNewCompany(this.ruleForm)
                        .then(res =>{
                            console.log(res)
                            const {code, message} = res.data
                            if(code === 200){
                                this.$message({message: message, type: 'success'});
                                this.$refs[formName].resetFields();
                            }else{
                                this.$message({message: message, type: 'warning'});
                            }
                        }).catch(err =>{
                            this.$message.error({message: err.message});
                        })
                    // alert('submit!');
                    this.isLoading = false
                } else {
                    console.log('error submit!!');
                    this.isLoading = false
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    }
}
</script>
<style lang="less" scoped>
.container{
    .title {
        background-color: #eee;
        color: #333;
        padding: 10px;
        font-weight: bold;
    }
    .content{
        padding: 0 3%;
        :deep(.el-form-item){
            margin-bottom:0;
            
        }
        :deep(.el-form--label-top .el-form-item__label){
            padding: 0;
            font-weight: bold
        }
        .btn-fields{
            text-align: right;
            margin-top: 15px;
        }
    }
}
</style>