<template>
    <el-descriptions class="margin-top" :title="title" :column="column" border>
        <template slot="extra">
            {{extra}}
        </template>
        <el-descriptions-item v-for="detail in items" :key="detail.title" :span="detail.span">
            <template slot="label">
                {{detail.title}}
            </template>
            {{detail.content}}
        </el-descriptions-item>
    </el-descriptions>
</template>

<script>
export default{
    name:"CommonDescription",
    props:["items", "title", "column", "extra"]
}
</script>

<style scoped lang="less">

:deep(.el-descriptions__header){
    margin-bottom: 10px;
    margin-top: 10px;
}
</style>