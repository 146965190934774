<template>
    <div class="container">
        <div class="title">
            <div>
                {{ruleForm.id}} 
                <el-tooltip class="item" v-if="ruleForm.remaining_days" effect="light" :content="ruleForm.remaining_days < 0? `${ruleForm.remaining_days} day(s) left`: `${ruleForm.remaining_days} day(s) passed`" placement="top" style="margin-top: 10px">
                    <template v-if="ruleForm.status === 1">
                        <font-awesome-icon v-if="ruleForm.remaining_days >30"  icon="fa-solid fa-battery-full" style="color: #5cb85c; font-size: 1.5rem;"/>
                        <font-awesome-icon v-else-if="ruleForm.remaining_days <= 30 && ruleForm.remaining_days >= 8" icon="fa fa-battery-three-quarters" style="color: #f1c40f; font-size: 1.5rem;"/>
                        <font-awesome-icon v-else-if="ruleForm.remaining_days <= 7 && ruleForm.remaining_days >= 4" icon="fa-solid fa-battery-half" style="color: #f1c40f; font-size: 1.5rem;"/>
                        <font-awesome-icon v-else-if="ruleForm.remaining_days <= 3 " icon="fa-solid fa-battery-quarter" style="color: red; font-size: 1.5rem;"/>
                    </template>
                    <template v-else-if="ruleForm.status === 2">
                        <font-awesome-icon icon="fa-solid fa-battery-empty" style="color: purple; font-size: 1.5rem;"/>
                    </template>
                </el-tooltip>
            </div>
            <el-button style="float:right" type="primary" icon="el-icon-download" :loading="isLoading" @click="pdfRegenerateHanlder" plain>
                {{ isLoading? "Loading..." : "View Certificate"}}
            </el-button>
            <!-- <el-dropdown style="float:right">
                <el-button type="primary" plain>
                    Certificate<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item icon="el-icon-download" @click.native="viewCertificate">View Certificate</el-dropdown-item>
                    <el-dropdown-item icon="el-icon-download" @click.native="pdfRegenerateHanlder">View Certificate</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown> -->
        </div>
        <div class="content">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top" label-width="100px" class="demo-ruleForm">
                <el-tabs v-model="activeTabName" @tab-click="handleClick">
                    <el-tab-pane label="General Details" name="first">
                        <el-row :gutter="20">
                            <el-col :span="24">
                                <el-form-item label="Serial Number" prop="serial">
                                    <el-input v-model="ruleForm.serial"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="Order Number" prop="order_number">
                                    <el-input v-model="ruleForm.order_number"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Ref Number" prop="ref_number">
                                    <el-input v-model="ruleForm.ref_number"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="Test Date" prop="test_date">
                                    <el-date-picker type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" v-model="ruleForm.test_date" style="width: 100%;"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Test Schedule" prop="test_schedule">
                                    <el-select v-model.number="ruleForm.test_schedule" filterable style="width: 100%">
                                        <el-option v-for="schedule in testScheduleOptions" :key="schedule.id" :label="schedule.name" :value="schedule.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="Retest Date" prop="time_retest">
                                    <el-date-picker type="date" :readonly="true" format="yyyy-MM-dd" value-format="yyyy-MM-dd" v-model="ruleForm.time_retest" style="width: 100%;"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12"></el-col>
                        </el-row>
                        <el-form-item label="Comments" prop="description">
                            <el-input type="textarea" max="3" min="2" v-model="ruleForm.description" resize="none"></el-input>
                        </el-form-item>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="Authorized By" prop="authorized_by">
                                    <!-- <el-input v-model="ruleForm.authorized_by" disabled></el-input> -->
                                    <el-select v-model="ruleForm.authorized_by" filterable style="width: 100%">
                                        <el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Customer" prop="customer">
                                    <el-select v-model="ruleForm.user_id" filterable style="width: 100%">
                                        <el-option v-for="customer in customers" :key="customer.id" :label="customer.name" :value="customer.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        
                        <el-row :gutter="20">
                            <el-col :span="24">
                                <el-form-item label="Status"  prop="status">
                                    <el-radio-group v-model="ruleForm.status" size="small">
                                        <el-radio v-for="item in statusOptions" :label="item.value" :key="item.name" border :disabled="item.disabled">{{item.name}}</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="Assembly Details" name="second">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="Hose Code" prop="code">
                                    <el-select v-model="ruleForm.code" filterable style="width: 100%">
                                        <el-option v-for="item in itemCodeOptions" :key="item.id" :label="item.code" :value="item.code"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col> 
                            <el-col :span="12">
                                <el-form-item label="Hose Type" prop="hosetype">
                                    <el-input v-model="ruleForm.hosetype" :disabled="isHosetypeDisabled"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="Static Length (mm)" prop="static_length">
                                    <el-input v-model.number="ruleForm.static_length">
                                        <el-select v-model="ruleForm.static_length_unit" slot="append" placeholder="Select" class="input-with-select">
                                            <el-option v-for="item in staticLengthUnitOptions" :key="item.name" :label="item.name" :value="item.value"></el-option>
                                        </el-select>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Nominal Bore (mm)" prop="nominal_bore">
                                    <el-input v-model.number="ruleForm.nominal_bore"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="4">
                                <el-form-item label="End A" prop="end_a_code">
                                    <el-select v-model="ruleForm.end_a_code" filterable style="width: 100%">
                                        <el-option v-for="item in endabOptions" :key="item.id" :label="item.code" :value="item.code"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="" prop="end_a">
                                    <br />
                                    <el-input v-model="ruleForm.end_a" :disabled="isEndADisabled"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="End A Retention" prop="end_a_retention">
                                    <el-select v-model="ruleForm.end_a_retention" filterable style="width: 100%">
                                        <el-option v-for="item in retentionOptions" :key="item.name" :label="item.name" :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="4">
                                <el-form-item label="End B" prop="end_b_code">
                                    <el-select v-model="ruleForm.end_b_code" filterable style="width: 100%">
                                        <el-option v-for="item in endabOptions" :key="item.id" :label="item.code" :value="item.code"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="" prop="end_b">
                                    <br />
                                    <el-input v-model="ruleForm.end_b" :disabled="isEndBDisabled"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="End B Retention" prop="end_b_retention">
                                    <el-select v-model="ruleForm.end_b_retention" filterable style="width: 100%">
                                        <el-option v-for="item in retentionOptions" :key="item.name" :label="item.name" :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="Assembly Location" prop="location">
                                    <el-select v-model="ruleForm.location" filterable style="width: 100%">
                                        <el-option v-for="item in locationOptions" :key="item.name" :label="item.name" :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Manufacture Date"  prop="time_manufactured">
                                    <el-date-picker type="date" format="yyyy-MM-dd" v-show="isTimeManufacturedDisplay" value-format="yyyy-MM-dd" v-model="ruleForm.time_manufactured" style="width: 70%; margin-right: 2%;" :picker-options="datePickerOptions"></el-date-picker>
                                    <el-input value="Not Available" :readonly="true" v-show="!isTimeManufacturedDisplay" style="width: 70%; margin-right: 2%;"></el-input>
                                    <el-checkbox v-model="is_time_manufactured_na" :true-label="1" :false-label="0" @change="manufacturedDateRenderChangeHandler">N/A</el-checkbox>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="Test Details" name="third">
                        <el-divider content-position="left"><h5>Pressure Test</h5></el-divider>
                        <el-row :gutter="20">
                            <el-col :span="24" style="text-align: right;">
                                <el-switch v-model.number="ruleForm.test_pressure_display" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0" active-text="Show on Certificate" inactive-text="Hide on Certificate"></el-switch>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Test Pressure" prop="test_pressure">
                                    <el-input v-model="ruleForm.test_pressure">
                                        <el-select v-model="ruleForm.test_pressure_unit" slot="append" placeholder="Select" class="input-with-select">
                                            <el-option v-for="item in testPressureUnitOptions" :key="item.name" :label="item.name" :value="item.value"></el-option>
                                        </el-select>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="" prop="test_pressure_result">
                                    <br />
                                    <el-switch v-model.number="ruleForm.test_pressure_result" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0" active-text="Pass" inactive-text="Fail"></el-switch>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <br />
                        <el-divider content-position="left"><h5>Elongation Test</h5></el-divider>
                        <el-row :gutter="20">
                            <el-col :span="24" style="text-align: right;">
                                <el-switch v-model.number="ruleForm.test_elongation_display" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0" active-text="Show on Certificate" inactive-text="Hide on Certificate"  @change="elongationDisplayChangeHandler"></el-switch>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Test Length (mm)" prop="test_length">
                                    <el-input v-model="ruleForm.test_length"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="" prop="test_length_result">
                                    <br />
                                    <el-switch v-model.number="ruleForm.test_length_result" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0" active-text="Pass" inactive-text="Fail"></el-switch>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="Final Length (mm)" prop="final_length">
                                    <el-input v-model="ruleForm.final_length"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="" prop="final_length_result">
                                    <br />
                                    <el-switch v-model.number="ruleForm.final_length_result" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0" active-text="Pass" inactive-text="Fail"></el-switch>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <br />
                        <el-divider content-position="left"><h5>Continuity & Electrical Resistant Test</h5></el-divider>
                        <el-row :gutter="20">
                            <el-col :span="24" style="text-align: right;">
                                <el-switch v-model.number="ruleForm.test_continuity_display" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0" active-text="Show on Certificate" inactive-text="Hide on Certificate"  @change="continuityDisplayChangeHandler"></el-switch>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Continuity" prop="continuity">
                                    <el-select v-model="ruleForm.continuity" filterable style="width: 100%">
                                        <el-option v-for="item in continuityOptions" :key="item.name" :label="item.name" :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="" prop="continuity_result">
                                    <br />
                                    <el-switch v-model.number="ruleForm.continuity_result" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0" active-text="Pass" inactive-text="Fail"></el-switch>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="Resistance (ohms)" prop="resistance">
                                    <el-input v-model="ruleForm.resistance">
                                        <template slot="append">ohms</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="" prop="resistance_result">
                                    <br />
                                    <el-switch v-model.number="ruleForm.resistance_result" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0" active-text="Pass" inactive-text="Fail"></el-switch>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                </el-tabs>

                <el-form-item class="btn-field" v-if="!isCustomer">
                    <el-button type="primary" @click="submitForm('ruleForm')" :disabled="isSubmiting">{{isSubmiting? "Loading...":"Submit"}}</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>

</template>

<script>
import { getItemById, updateItemsById } from "../../../api/items";
import { getEmployees, getAllCustomers } from "../../../api/user";

import { pdfGenerate } from "../../../api/pdf"
import { mapState } from "vuex";
import Item from "@/mixins/item";
import moment from "moment";

export default {
    mixins: [Item],
    data() {
        return {
            ruleForm: {
                serial: "",
                test_schedule: "",
                time_retest: "",
                order_number: "",
                ref_number: "",
                authorized_by: "",
                description: "",
                hosetype:"",
                code:"",
                static_length: "",
                static_length_unit: 1,
                nominal_bore: "",
                end_a: "",
                end_a_code:"",
                end_a_retention:"",
                end_b:"",
                end_b_code:"",
                end_b_retention:"",
                location:"",
                time_manufactured:"",
                remaining_days: 0,
                test_pressure:"",
                test_pressure_unit:1,
                test_pressure_result:0,
                test_length:"",
                test_length_result:0,
                final_length:"",
                final_length_result:0,
                continuity:"",
                continuity_result:0,
                resistance:"",
                resistance_result:0,
                authorized_by: "",
                user_id: "",
                certificate:"",
                status: ""
            },
            isLoading: false,
            isSubmiting: false,
            isHosetypeDisabled: true,
            isEndADisabled: true,
            isEndBDisabled: true,
            isTimeManufacturedDisplay: true,
            employees:[],
            is_time_manufactured_na: 0,
            originalData:{
                time_manufactured: null
            },
            datePickerOptions:{
                shortcuts: [
                    {
                        text: 'Today',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    }, 
                    {
                        text: 'Yesterday',
                        onClick(picker) {
                        const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    }, 
                    {
                        text: 'A week ago',
                        onClick(picker) {
                        const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    }
                ]
            },
            customers:[]
        };
    },
    created(){
        this.fetchDatails()
    },
    mounted(){
        // console.log(this.$route,"route")
    },
    methods: {
        fetchDatails(){
            
            this.getItemOptions()

            this.getItemDetailsById()
            this.getEmployeeList()
            this.getCustomerList()
        },
        submitForm(formName) {
            this.isSubmiting = true
            this.$refs[formName].validate((valid) => {

                // console.log(this.ruleForm)
                if (valid) {
                    updateItemsById(this.ruleForm).then(res=> {
                        const {code, message} = res.data
                        if(code === 200){
                            this.$message({message: message, type: 'success'});
                        }else{
                            this.$message.error({message: message});
                        }
                        
                    }).catch(err => {
                        this.$message.error({message: err.message});
                        console.error(err.message)
                        
                    })
                    setTimeout(() => {
                       this.isSubmiting = false 
                    }, 2000);
                    
                } else {
                    this.$message.error({message: "Some errors on the form, please check"})
                    this.isSubmiting = false
                    // console.log('error submit!!');
                    return false;
                }
            });
        },
        handleClick(tab, event) {
            // console.log(tab, event);
        },
        pdfRegenerateHanlder(){
            this.isLoading = true
            // this.$confirm('Are you sure to regenerate the PDF?', '', {
            //     confirmButtonText: 'Yes',
            //     cancelButtonText: 'No',
            //     type: 'warning'
            // }).then(() => {
            //     pdfGenerate({id: this.$route.params.id}).then(res =>{
            //         // console.log(res)
            //         this.ruleForm.certificate = res.data.data.certificate
            //         this.$message({ type: 'success', message: res.data.message });
            //     }).catch(err =>{
            //         console.error(err.message)
            //         this.$message.error({message: err.message});
            //     })
            // }).catch(() => {
            //     this.$message({ type: 'info', message: '已取消删除' });          
            // });

            pdfGenerate({id: this.$route.params.id}).then(res =>{
                const { code, data, message } = res.data
                if(code === 200) {
                    window.open(data.certificate, '_blank');
                    this.$message({ type: 'success', message });
                }
                this.isLoading = false
            }).catch(err =>{
                console.error(err.message)
                this.$message.error({message: err.message});
                this.isLoading = false
            })
        },
        getEmployeeList(){
            getEmployees().then(res => {
                const {code, data} = res.data
                if(code === 200){
                    this.employees = data
                }
            }).catch(err=>err)
        },
        getItemDetailsById(){
            getItemById(this.$route.params.id).then(res =>{
                // console.log(res,"getItemById")
                const {data, code} = res.data
                this.ruleForm = data
                this.originalData.hosetype = data.hosetype
                this.originalData.end_a = data.end_a
                this.originalData.end_b = data.end_b
                if(data.end_a_code === "MF") this.isEndADisabled = false
                if(data.end_b_code === "MF") this.isEndBDisabled = false
                if(data.code === "MH") this.isHosetypeDisabled = false
                if(data.time_manufactured === "1900-01-01 00:00:00") {
                    this.isTimeManufacturedDisplay = false
                    this.is_time_manufactured_na = 1
                }else{
                    this.originalData.time_manufactured = data.time_manufactured
                }
            }).catch(err => {
                console.error(err.message)
                this.$message.error({message: err.message});
            })
        },
        manufacturedDateRenderChangeHandler(value){
            this.is_time_manufactured_na = value
            if(value){
                this.isTimeManufacturedDisplay = false
                this.ruleForm.time_manufactured = "1900-01-01"
            }else{
                this.isTimeManufacturedDisplay = true
                if(this.originalData.time_manufactured !== null){
                    this.ruleForm.time_manufactured = this.originalData.time_manufactured
                }
            }
        },
        viewCertificate(){
            window.open(this.ruleForm.certificate, '_blank');
        },
        getCustomerList(){
            getAllCustomers().then(res => {
                const {code, data} = res.data
            if(code === 200){
                this.customers = data
            }
            }).catch(err=>err)
        }
    },
    computed:{
        ...mapState("login",["userinfo"]),
        isCustomer(){
            return this.userinfo.role === "customer" 
        },
        fileName(){
            if(!this.ruleForm.certificate) return''
            const parts = this.ruleForm.certificate.split("/")
            return parts[parts.length - 1]
        }
    },
    watch:{
        "ruleForm.test_schedule":{
            handler(newValue, oldValue) {
                if(this.ruleForm.test_date && newValue)this.ruleForm.time_retest = moment(this.ruleForm.test_date).add(newValue,"M").format('YYYY-MM-DD')
            },
            deep: true,           
        },
        "ruleForm.code":{
            handler(newValue, oldValue) {
                // if(oldValue != "")this.ruleForm.hosetype = this.itemCodeOptions.find(res => res.code === newValue)?.description
                console.log(this.itemCodeOptions.find(res => res.code === newValue),"happy")
                if(oldValue != ""){
                    if(newValue === "MH"){
                        this.ruleForm.hosetype = this.originalData.hosetype
                        this.isHosetypeDisabled = false
                    }else{
                        this.ruleForm.hosetype = this.itemCodeOptions.find(res => res.code === newValue)?.description
                        this.isHosetypeDisabled = true
                    }
                }

                const isComposiste = this.itemCodeOptions.find(res => res.code === newValue)?.is_composiste

                if(isComposiste === 0){
                    this.removeElongationTestValidate()
                    this.removeContinuityTestValidate()
                }else{
                    this.addElongationTestValidate()
                    this.addContinuityTestValidate()
                }

                this.clearValidate()

                this.ruleForm.test_elongation_display = isComposiste
                this.ruleForm.test_continuity_display = isComposiste
            },
            // deep: true,
        },
        "ruleForm.end_a_code":{
            handler(newValue, oldValue) {
                // console.log(newValue)
                if(oldValue != ""){
                    if(newValue === "MF"){
                        this.ruleForm.end_a = this.originalData.end_a
                        this.isEndADisabled = false
                    }else{
                        this.ruleForm.end_a = this.endabOptions.find(res => res.code === newValue)?.description
                        this.isEndADisabled = true
                    }
                }
            },
            deep: true,
        },
        "ruleForm.end_b_code":{
            handler(newValue, oldValue) {
                // console.log(7)
                if(oldValue != ""){
                    if(newValue === "MF"){
                        this.ruleForm.end_b = this.originalData.end_b
                        this.isEndBDisabled = false
                    }else{
                        this.ruleForm.end_b = this.endabOptions.find(res => res.code === newValue)?.description
                        this.isEndBDisabled = true
                    }
                }
                // this.ruleForm.end_b = this.endabOptions.find(res => res.code === newValue)?.description
            },
            deep: true,
        }
    }
}
</script>
<style lang="less" scoped>
.container{
    .title {
        // background-color: #eee;
        font-size: 1.8rem;
        color: #333;
        padding: 10px;
        font-weight: bold;
        display: flex;
        gap: 15px;
        justify-content: space-between;
    }
    .content{
        // padding: 1% 3%;

        :deep(.el-row){
            margin-bottom: 10px;
        }

        :deep(.el-form-item){
            margin-bottom:0;
            
        }
        :deep(.el-form--label-top .el-form-item__label){
            padding: 0;
            font-weight: bold
        }

        .btn-field{
            float: right;
            margin-top: 20px;
        }

        :deep(.el-tab-pane){
            padding-bottom: 25px;
        }

        :deep(.el-input-group__append){
            width: 15%;
        }
    }
}
</style>