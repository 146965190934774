<template>
    <div class="header-container">
        <div class="l-content">
            <el-button style="margin-right:20px" @click="handleMenu" icon="el-icon-menu" size="mini"></el-button>
            <!-- <span class="text">SHOUYE</span> -->
            <!-- <el-breadcrumb separator="/">
                <el-breadcrumb-item v-for="item in tags" :key="item.path" :to="{ path: item.path }">{{item.label}}</el-breadcrumb-item>
            </el-breadcrumb> -->
        </div>
        <div class="r-content">
            <el-dropdown @command="handleClick">
                <span class="el-dropdown-link">
                    <!-- <el-avatar :size="size" :src="circleUrl"></el-avatar> -->
                    <avatar :username="userinfo.user " :size="28"></avatar>
                    <!-- {{ userinfo.user }} -->
                </span>
                <el-dropdown-menu slot="dropdown">
                    <!-- <el-dropdown-item>个人中心</el-dropdown-item> -->
                    <el-dropdown-item command="logout">Logout</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
// import Cookie from "js-cookie"
import { mapMutations } from "vuex";
import Avatar from "vue-avatar"
export default{
    components:{
        Avatar
    },
    mounted(){
        console.log()
    },
    data(){
        return{
            circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
            size: "small"
        }
    },
    methods:{
        ...mapMutations("login",["deleteUser"]),
        ...mapMutations("menu",["deleteMenuList"]),
        handleMenu(){
            this.$store.commit("collapseMenu")
        },
        handleClick(command){
            if(command==="logout"){
                // Cookie.remove("token")
                // Cookie.remove("menu")
                this.deleteUser()
                this.deleteMenuList()
                localStorage.clear()
                this.$router.replace("/login")
            }
            
        }
    },
    computed:{
        ...mapState("login",["userinfo"]),
        // ...mapState({
        //     tags: state => state.tab.tabList
        // })
    },
}
</script>
<style lang="less" scoped>
.header-container{
    padding: 0 20px;
    background-color: #333;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text{
        color: #fff;
        font-size: 14px;
        margin-left: 10px;
    }
    .r-content{
        .user{
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }
    }
    .l-content{
        display: flex;
        align-items: center;
        :deep(.el-breadcrumb__item){
            .el-breadcrumb__inner{
                font-weight: normal;
                &.is-link{
                    color: #666;
                }
            }
            &:last-child{
                .el-breadcrumb__inner{
                     color: #fff;
                }
            }
        }
    }
}
</style>