<template>
    <div>
        <el-row :gutter="20">
            <el-col :span="12">
                <el-button type="primary" @click="addUser">Add New Company</el-button>
            </el-col>
            <el-col :span="12" style="text-align: right;">
                <el-input placeholder="Search(Name)" v-model="searchData" @input="seachHandler" clearable autofocus style="width: 300px;">
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
            </el-col>
        </el-row>
        <el-table :data="tableData" stripe style="width: 100%" @row-dblclick="handleEdit">
            <el-table-column prop="name" label="Name"></el-table-column>
            <el-table-column prop="email" label="Email"></el-table-column>
            <el-table-column prop="phone" label="Phone"></el-table-column>
            <el-table-column prop="address_line1" label="Address"></el-table-column>
            <el-table-column prop="address_city" label="City" width="100"></el-table-column>
            <el-table-column label="">
                <template slot-scope="scope">
                    <!-- <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">edit</el-button> -->
                    <el-button size="mini" type="danger" plain @click="handleDelete(scope.$index, scope.row)"><i class="el-icon-delete"></i></el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @current-change="getPagination" :current-page="currentPage" :page-sizes="[100, 200, 300, 400]"
            :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
import { getCompaniesTableData, deleteCompanyById } from "../../../api/companies";
// import Pagination from "../../../components/pagination";
import { debounce } from "lodash"

export default {
    // components:{
    //     Pagination
    // },
    data() {
        return {
            tableData: [],
            total:0,
            pageSize: 100,
            currentPage: 1,
            searchData: "",
        }
    },
    created(){
        this.getTableList()
    },
    methods:{
        getPagination(page){

            this.currentPage = page

            this.getTableList()
        },
        getTableList(){
            const {searchData, pageSize, currentPage} = this

            getCompaniesTableData({page_num: currentPage, searchText: searchData, page_size: pageSize})
                .then(res => {
                    // console.log(res)
                    if(res.data.code === 200){
                        this.tableData = res.data.data.result
                        this.total = res.data.data.page.total
                        this.pageSize = res.data.data.page.page_size
                    }
                })
                .catch(err => {
                    console.log(err,"ss")
                    this.$message.error(err.message);

                })
        },
        addUser(){
            this.$router.push({name:"addCompany"})
        },
        handleEdit(row, column, event) {
            // console.log(index, row);
            this.$router.push({name:"viewCompany", params:{ id: row.id}})
        },
        handleDelete(index, row) {
            // console.log(index, row);
    
            this.$confirm('Are you sure to delete this company?', '', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'warning'
            }).then(() => {
                deleteCompanyById(row.id)
                    .then(res =>{
                        // console.log(res)
                        const {code, message, data} = res.data
                        if(code === 200){
                            this.$message({message: message, type: 'success'});
                            this.tableData = this.tableData.filter(res => res.id !== data.id)
                        }else{
                            this.$message({message: message, type: 'warning'});
                        }
                    }).catch(err =>{
                        this.$message.error({message: err.message});
                    })
            }).catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: 'Action '
                // });          
            });
        },
        seachHandler: debounce(function (query) {
            this.searchData = query
            this.getTableList()
        }, 1000),
        
    }
}
</script>