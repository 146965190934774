import http from "@/utils/request";

export const getAllEmployee = () =>{
  return http.get('/api/employee/getAllEmployee')
}

export const addNewEmployee = (data) =>{
  return http.post('/api/employee/addEmployee', data)
}

export const deleteEmployeeById = (id) =>{
  return http.delete(`/api/employee/${id}`)
}

export const uploadEmployeeImage = (employeeId, data) =>{
  return http.post(`/api/employee/upload/${employeeId}`, data, { headers: {"Content-Type": "multipart/form-data"}})
}